.rd3t-link {
    fill: none;
    stroke: black !IMPORTANT;
}
.rd3t-link-transparent {
    fill: none;
    stroke: rgba(0, 0, 0, 0.1) !IMPORTANT;
}
.rd3t-link-hovered {
    fill: none;
    stroke: red !IMPORTANT;
    stroke-width: 2px
}
.link0 {
    fill: none;
    stroke: #4d607d !IMPORTANT;
}
.link1 {
    fill: none;
    stroke: #727272 !IMPORTANT;
}
.link2 {
    fill: none;
    stroke: #727272 !IMPORTANT;
}
.link3 {
    fill: none;
    stroke: #727272 !IMPORTANT;
}
.link4 {
    fill: none;
    stroke: #727272 !IMPORTANT;
}
.link5 {
    fill: none;
    stroke: #727272 !IMPORTANT;
}
